/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Sticky Menus

        // Home Sticky
       // $("#header-homie").sticky({topSpacing:0, zIndex: 900});
        // Page Sticky
        //$(".head-block").sticky({topSpacing:0, zIndex: 900});
        //$(".head-block").on("sticky-start", function() { $(this).addClass("hpad"); });
        //$(".head-block").on("sticky-end", function() { $(this).removeClass("hpad");$(".head-block").sticky({topSpacing:0, zIndex: 900}); });
        /*
        var s = $(".head-block");
        var pos = s.position();
        $(window).scroll(function() {
          var windowpos = $(window).scrollTop()+25;
          if (windowpos >= 50) {
              s.addClass("shadow");
          } else {
              s.removeClass("shadow");
          }
        });
        */
        $( ".shiftnav-toggle" ).click(function() {
          $("body").append("<div class=\"overlay\"></div>");
        });
        // Match height
        $('.cta-box').matchHeight();

        $('.grid-content').matchHeight({ byRow: false });

        $( document ).ajaxComplete(function() {
          $( ".squad-title h4 a" ).click(function(e) {
            e.preventDefault();
            var image = $(this).parent().parent().parent().parent().parent().parent().prev().find('img').attr("src");
            var title = $(this).html();
            var content = $(this).parent().parent().next().html();
            $('#dynamicModal').on('show.bs.modal', function (event) {
              var modal = $(this);
              modal.find('.modal-title').html(title);
              modal.find('.modal-body .image').html('<img class="img-fluid" src="'+ image +'" />');
              modal.find('.modal-body .bio').html(content);
            });
            $('#dynamicModal').modal();
          });
        });

        $( document ).ajaxComplete(function() {
          $( ".squad-image" ).click(function(e) {
            e.preventDefault();
            var image = $(this).find('img').attr("src");
            var title = $(this).parent().next().find('h4 a').html();
            var content = $(this).parent().next().find('.vc_gitem-acf').html();
            $('#dynamicModal').on('show.bs.modal', function (event) {
              var modal = $(this);
              modal.find('.modal-title').html(title);
              modal.find('.modal-body .image').html('<img class="img-fluid" src="'+ image +'" />');
              modal.find('.modal-body .bio').html(content);
            });
            $('#dynamicModal').modal();
          });
        });

        $("[data-fancybox]").fancybox({
		      // Options will go here
          thumbs : {
            autoStart   : true,
            hideOnClose : true
	        }
	      });

        $(function () {
          $('[data-toggle="popover"]').popover({
            html: true,
            content: function() {
                  var content = $(this).attr("data-popover-content");
                  return $(content).children(".popover-body").html();
                },
                title: function() {
                  var title = $(this).attr("data-popover-content");
                  return $(title).children(".popover-heading").html();
                }
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
